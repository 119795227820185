.carfountain_details {
  padding: 0 60px;

  .ant-tabs-nav {
    flex-direction: row-reverse;
  }

  .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }

  .ant-tabs-nav:before {
    border: none;
  }

  .details-main {
    display: flex;
    justify-content: space-between;

    .details-right {
      width: 50%;
    }
  }

}