.play-main {
  width: 35vw;
  height: 600px;
  background-color: aqua;
  position: relative;
}

.play-button {
  width: 150px;
  height: 200px;
  border-radius: 20px;
  position: absolute;
  top: 600px;
  left: 25%;
  transform: translate(-50%, -50%);
}