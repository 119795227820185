.headTag {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 700px;

  .headSearch {
    position: absolute;
    right: 32%;
    top: 153px;
  }

  .headTab {
    position: absolute;
    right: 20%;
    top: 163px
  }
}