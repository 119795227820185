.tagName {
  display: flex;
  flex-direction: column;
  width: 200px;
  text-align: left;
  margin-bottom: 10px;

  .tagState {
    margin-bottom: 5px;
  }

}

.tableList {
  display: flex;
  flex-wrap: wrap;

  .table {
    width: 13%;
    margin: 10px;
  }
}