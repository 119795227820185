.headSeach {
  // margin-top: -5px;
  position: absolute;
  left: -140%;
  top: -12px;
}

.headSpace {
  width: 130px;
  margin-left: 80%;
}