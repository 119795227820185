.carfountain_home {
  padding: 0 60px;

  .ant-tabs-nav {
    flex-direction: row-reverse;
  }

  .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }

  .ant-tabs-nav:before {
    border: none;
  }

  .cardList {
    display: flex;
    flex-wrap: wrap;
  }
}