.set-title {
  display: flex;
  width: 300px;

  .set-span {
    width: 60px;
  }
}

.set-title-two {
  display: flex;
  width: 300px;
  margin-top: 20px;

  .set-span {
    width: 60px;
  }
}