.article-main {

  .acticle-list {
    margin-left: 40px;
  }

  .acticle-title {
    width: 150px;
    margin-top: 10px;
  }

  .acticle-name {
    margin-top: 10px;
    text-align: left !important;
  }
}

.ant-col-12 {
  text-align: right;
}