.carfountain_labeldetails {
  padding: 0 60px;

  .ant-tabs-nav {
    flex-direction: row-reverse;
  }

  .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }

  .ant-tabs-nav:before {
    border: none;
  }

  .label-title {
    color: #000;
    font-weight: 600;
    font-size: 24px;

    .label-button {
      margin-left: 40px;

      .label-detel {
        margin-left: 20px;
      }
    }
  }

  .cardList {
    display: flex;
    flex-wrap: wrap;
  }
}